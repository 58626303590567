import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import AuthError from '../../pages/AuthError';

import WebsocketManager from '../../WebsocketManager';
import Modal from 'react-modal';
import api from '../../services/api';
import { ModalTitle, ModalFooter, ConfirmButton } from './styles';
import { LoadingPage } from 'ui-kit-takeat';
import { uuid } from 'uuidv4';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [websocketManager, setWebsocketManager] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorAction, setErrorAction] = useState([]);
  const [modalErrorPhrase, setModalErrorPhrase] = useState();
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [requireTableNumber, setRequireTableNumber] = useState(() => {
    const requireTableNumberStorage = localStorage.getItem(
      '@garcomdigital:requireTableNumber',
    );
    if (requireTableNumberStorage === 'true') return true;
    if (requireTableNumberStorage === 'false') return false;
    return null;
  });

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:requireTableNumber',
      requireTableNumber,
    );
  }, [requireTableNumber]);

  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
    overlay: {
      zIndex: 2,
    },
  };
  const [loadingPix, setLoadingPix] = useState(false);
  const [phraseToPix, setPhraseToPix] = useState(false);
  const [userToken, setUserToken] = useState(() => {
    const tokenStorage = localStorage.getItem('@garcomdigital:userToken');

    if (tokenStorage) {
      api.defaults.headers.Authorization = `Bearer ${tokenStorage}`;
      return tokenStorage;
    }

    return '';
  });

  function toggleModal() {
    setIsModalErrorOpen(false);
  }

  const [userPhone, setUserphone] = useState(() => {
    const authStorage = localStorage.getItem('@garcomdigital:userPhone');

    if (authStorage) {
      return authStorage;
    }

    return '';
  });

  const [lastPixCode, setLastPixCode] = useState(() => {
    const storage = localStorage.getItem('@garcomdigital:lastPixCode');

    if (storage) {
      return storage;
    }

    return '';
  });

  const [userName, setUserName] = useState(() => {
    const userNameStorage = localStorage.getItem('@garcomdigital:userName');

    if (userNameStorage) {
      return userNameStorage;
    }

    return '';
  });

  const [sessionKey, setSessionKey] = useState(() => {
    const sessionKeyStorage = localStorage.getItem('@garcomdigital:sessionKey');

    if (sessionKeyStorage) {
      return sessionKeyStorage;
    }

    return '';
  });

  const [clube, setClube] = useState(() => {
    const sessionKeyStorage = JSON.parse(
      localStorage.getItem('@garcomdigital:clube'),
    );

    if (sessionKeyStorage) {
      return sessionKeyStorage;
    }

    return '';
  });

  const [restaurantId, setRestaurantId] = useState(() => {
    const restaurantIdStorage = localStorage.getItem(
      '@garcomdigital:restaurantId',
    );

    if (restaurantIdStorage) {
      return Number(restaurantIdStorage);
    }

    return '';
  });

  const [restaurantName, setRestaurantName] = useState(() => {
    const restaurantNameStorage = localStorage.getItem(
      '@garcomdigital:restaurantName',
    );

    if (restaurantNameStorage) {
      return restaurantNameStorage;
    }

    return '';
  });

  const [restaurantFantasyName, setRestaurantFantasyName] = useState(() => {
    const restaurantFantasyNameStorage = localStorage.getItem(
      '@garcomdigital:restaurantFantasyName',
    );

    if (restaurantFantasyNameStorage) {
      return restaurantFantasyNameStorage;
    }

    return '';
  });

  const [restaurantAvatar, setRestaurantAvatar] = useState(() => {
    const restaurantAvatarStorage = localStorage.getItem(
      '@garcomdigital:restaurantAvatar',
    );

    if (restaurantAvatarStorage) {
      return restaurantAvatarStorage;
    }

    return '';
  });

  const [restaurantIsOpen, setRestaurantIsOpen] = useState(() => {
    const restaurantIsOpenStorage = localStorage.getItem(
      '@garcomdigital:restaurantIsOpen',
    );

    if (restaurantIsOpenStorage) {
      return restaurantIsOpenStorage;
    }

    return '';
  });

  const [tableNumber, setTableNumber] = useState(() => {
    const tableNumberStorage = localStorage.getItem(
      '@garcomdigital:tableNumber',
    );

    if (tableNumberStorage) {
      return tableNumberStorage;
    }

    return 2;
  });

  const [tableKey, setTableKey] = useState(() => {
    const tableKeyStorage = localStorage.getItem('@garcomdigital:tableKey');

    if (tableKeyStorage) {
      return tableKeyStorage;
    }

    return '';
  });

  const [tableType, setTableType] = useState(() => {
    const tableTypeStorage = localStorage.getItem('@garcomdigital:tableType');

    if (tableTypeStorage) {
      return tableTypeStorage;
    }

    return '';
  });

  const [tableId, setTableId] = useState(() => {
    const tableIdStorage = localStorage.getItem('@garcomdigital:tableId');

    if (tableIdStorage) {
      return tableIdStorage;
    }

    return '';
  });

  const [billId, setBillId] = useState(() => {
    const billIdStorage = localStorage.getItem('@garcomdigital:billId');

    if (billIdStorage) {
      return billIdStorage;
    }

    return '';
  });

  const [basketId, setBasketId] = useState(() => {
    const basketIdStorage = localStorage.getItem('@garcomdigital:basketId');

    if (basketIdStorage) {
      return basketIdStorage;
    }

    return '';
  });

  const [restaurantTaxService, setRestaurantTaxService] = useState(() => {
    const restaurantTaxServiceStorage = localStorage.getItem(
      '@garcomdigital:restaurantTaxService',
    );

    if (restaurantTaxServiceStorage) {
      return restaurantTaxServiceStorage;
    }

    return '';
  });

  const [restaurantTaxServiceValue, setRestaurantTaxServiceValue] = useState(
    () => {
      const restaurantTaxServiceStorage = localStorage.getItem(
        '@garcomdigital:restaurantTaxServiceValue',
      );

      if (restaurantTaxServiceStorage) {
        return restaurantTaxServiceStorage;
      }

      return '';
    },
  );

  const [restaurantLocationLimited, setRestaurantLocationLimited] = useState(
    () => {
      const restaurantLocationLimitedStorage = localStorage.getItem(
        '@garcomdigital:restaurantLocationLimited',
      );

      if (restaurantLocationLimitedStorage) {
        return restaurantLocationLimitedStorage;
      }

      return '';
    },
  );

  const [instagram, setInstagram] = useState(() => {
    const restaurantInstagram = localStorage.getItem(
      '@garcomdigital:instagram',
    );

    if (restaurantInstagram) {
      return restaurantInstagram;
    }

    return '';
  });

  const [restaurantPhone, setRestaurantPhone] = useState(() => {
    const restaurantPhoneStorage = localStorage.getItem(
      '@garcomdigital:restaurantPhone',
    );

    if (restaurantPhoneStorage) {
      return restaurantPhoneStorage;
    }

    return '';
  });

  const [tokenClube, setTokenClube] = useState(() => {
    const tokenClube = localStorage.getItem('@garcomdigital:tokenClube');

    if (tokenClube) {
      return tokenClube;
    }

    return '';
  });

  const [keyClube, setKeyClube] = useState(() => {
    const keyClube = localStorage.getItem('@garcomdigital:keyClube');

    if (keyClube) {
      return keyClube;
    }

    return '';
  });

  const [hasClube, setHasClube] = useState(() => {
    const hasClube = localStorage.getItem('@garcomdigital:hasClube');

    if (hasClube) {
      return hasClube;
    }

    return '';
  });

  const [restaurantAcceptsPix, setRestaurantAcceptsPix] = useState(() => {
    const restaurantAcceptsPixStorage = localStorage.getItem(
      '@garcomdigital:restaurantAcceptsPix',
    );

    if (restaurantAcceptsPixStorage) {
      return restaurantAcceptsPixStorage;
    }

    return '';
  });

  const [restaurantDeliveryPayments, setRestaurantDeliveryPayments] = useState(
    () => {
      const restaurantDeliveryPaymentsStorage = localStorage.getItem(
        '@garcomdigital:restaurantDeliveryPayments',
      );

      if (restaurantDeliveryPaymentsStorage) {
        return JSON.parse(restaurantDeliveryPaymentsStorage);
      }

      return '';
    },
  );

  const [restaurantCurrency, setRestaurantCurrency] = useState(() => {
    const restaurantCurrencyStorage = localStorage.getItem(
      '@garcomdigital:restaurantCurrency',
    );

    if (restaurantCurrencyStorage) {
      return restaurantCurrencyStorage;
    }

    return '';
  });

  const [restaurantUseCommand, setRestaurantUseCommand] = useState(() => {
    const restaurantUseCommandStorage = localStorage.getItem(
      '@garcomdigital:restaurantUseCommand',
    );

    if (restaurantUseCommandStorage) {
      return restaurantUseCommandStorage;
    }

    return false;
  });

  const [restaurantOnlyQrcode, setRestaurantOnlyQrcode] = useState(() => {
    const restaurantOnlyQrcodeStorage = localStorage.getItem(
      '@garcomdigital:restaurantOnlyQrcode',
    );

    if (restaurantOnlyQrcodeStorage) {
      return restaurantOnlyQrcodeStorage;
    }

    return false;
  });

  const [restaurantOnlyDelivery, setRestaurantOnlyDelivery] = useState(() => {
    const restaurantOnlyDeliveryStorage = localStorage.getItem(
      '@garcomdigital:restaurantOnlyDelivery',
    );

    if (restaurantOnlyDeliveryStorage) {
      return restaurantOnlyDeliveryStorage;
    }

    return false;
  });

  const [userLocation, setUserLocation] = useState(() => {
    const userLocationStorage = localStorage.getItem(
      '@garcomdigital:userLocation',
    );

    if (userLocationStorage) {
      return JSON.parse(userLocationStorage);
    }

    return {};
  });

  const [userRecentlyProduct, setUserRecentlyProduct] = useState(() => {
    const userRecentlyProductStorage = localStorage.getItem(
      '@garcomdigital:userRecentlyProduct',
    );

    if (userRecentlyProductStorage) {
      return JSON.parse(userRecentlyProductStorage);
    }

    return {};
  });

  // PicPayService
  const [orderPicPayId, setOrderPicPayId] = useState(() => {
    const orderPicPayIdStorage = localStorage.getItem(
      '@garcomdigital:orderPicPayId',
    );

    if (orderPicPayIdStorage) {
      return orderPicPayIdStorage;
    }

    return '';
  });

  const [picpayUrlPayment, setPicpayUrlPayment] = useState(() => {
    const picpayUrlPaymentStorage = localStorage.getItem(
      '@garcomdigital:picpayUrlPayment',
    );

    if (picpayUrlPaymentStorage) {
      return picpayUrlPaymentStorage;
    }

    return '';
  });

  const [picpayReferenceId, setPicpayReferenceId] = useState(() => {
    const picpayReferenceIdStorage = localStorage.getItem(
      '@garcomdigital:picpayReferenceId',
    );

    if (picpayReferenceIdStorage) {
      return picpayReferenceIdStorage;
    }

    return '';
  });

  // Delivery Service
  const [userFirstName, setUserFirstName] = useState(() => {
    const userFirstNameStorage = localStorage.getItem(
      '@garcomdigital:userFirstName',
    );

    if (userFirstNameStorage) {
      return userFirstNameStorage;
    }

    return '';
  });

  const [userLastName, setUserLastName] = useState(() => {
    const userLastNameStorage = localStorage.getItem(
      '@garcomdigital:userLastName',
    );

    if (userLastNameStorage) {
      return userLastNameStorage;
    }

    return '';
  });

  const [userEmail, setUserEmail] = useState(() => {
    const userEmailStorage = localStorage.getItem('@garcomdigital:userEmail');

    if (userEmailStorage) {
      return userEmailStorage;
    }

    return '';
  });

  const [userChosePaySms, setUserChosePaySms] = useState(() => {
    const userChosePaySmsStorage = localStorage.getItem(
      '@garcomdigital:userChosePaySms',
    );

    if (userChosePaySmsStorage) {
      return userChosePaySmsStorage;
    }

    return '';
  });

  const [clientPaySms, setClientPaySms] = useState(() => {
    const clientPaySmsStorage = localStorage.getItem(
      '@garcomdigital:clientPaySms',
    );

    if (clientPaySmsStorage) {
      return clientPaySmsStorage;
    }

    return '';
  });

  const [isSmsServiceOptional, setIsSmsServiceOptional] = useState(() => {
    const isSmsServiceOptionalStorage = localStorage.getItem(
      '@garcomdigital:isSmsServiceOptional',
    );

    if (isSmsServiceOptionalStorage) {
      return isSmsServiceOptionalStorage;
    }

    return '';
  });

  const [pixCodePayment, setPixCodePayment] = useState(() => {
    const pixCodePaymentStorage = localStorage.getItem(
      '@garcomdigital:pixCodePayment',
    );

    if (pixCodePaymentStorage) {
      return pixCodePaymentStorage;
    }

    return '';
  });

  const [zoopPixCodePayment, setZoopPixCodePayment] = useState(() => {
    const zoopPixCodePaymentStorage = localStorage.getItem(
      '@garcomdigital:zoopPixCodePayment',
    );

    if (zoopPixCodePaymentStorage) {
      return zoopPixCodePaymentStorage;
    }

    return '';
  });
  const [restaurantHasSmsService, setRestaurantHasSmsService] = useState(() => {
    const restaurantHasSmsServiceStorage = localStorage.getItem(
      '@garcomdigital:restaurantHasSmsService',
    );

    if (restaurantHasSmsServiceStorage) {
      return restaurantHasSmsServiceStorage;
    }

    return '';
  });

  const [userPaymentMethod, setUserPaymentMethod] = useState(() => {
    const userPaymentMethodStorage = localStorage.getItem(
      '@garcomdigital:userPaymentMethod',
    );

    if (userPaymentMethodStorage) {
      return JSON.parse(userPaymentMethodStorage);
    }

    return '';
  });

  const [restaurantAcceptsPicpay, setRestaurantAcceptsPicpay] = useState(() => {
    const restaurantAcceptsPicpayStorage = localStorage.getItem(
      '@garcomdigital:restaurantAcceptsPicpay',
    );

    if (restaurantAcceptsPicpayStorage) {
      return restaurantAcceptsPicpayStorage;
    }

    return '';
  });

  const [restaurantAcceptsPaytime, setRestaurantAcceptsPaytime] = useState(
    () => {
      const restaurantAcceptsPaytimeStorage = localStorage.getItem(
        '@garcomdigital:restaurantAcceptsPaytime',
      );

      if (restaurantAcceptsPaytimeStorage) {
        return restaurantAcceptsPaytimeStorage;
      }

      return '';
    },
  );

  const [
    restaurantAcceptsWithdrawal,
    setRestaurantAcceptsWithdrawal,
  ] = useState(() => {
    const restaurantAcceptsWithdrawalStorage = localStorage.getItem(
      '@garcomdigital:restaurantAcceptsWithdrawal',
    );

    if (restaurantAcceptsWithdrawalStorage) {
      return restaurantAcceptsWithdrawalStorage;
    }

    return '';
  });

  const [
    restaurantAcceptsPaymentAtWithdrawal,
    setRestaurantAcceptsPaymentAtWithdrawal,
  ] = useState(() => {
    const restaurantAcceptsPaymentAtWithdrawalStorage = localStorage.getItem(
      '@garcomdigital:restaurantAcceptsPaymentAtWithdrawal',
    );

    if (restaurantAcceptsPaymentAtWithdrawalStorage) {
      return restaurantAcceptsPaymentAtWithdrawalStorage;
    }

    return '';
  });

  const [restaurantRequiresUserCEP, setRestaurantRequiresUserCEP] = useState(
    () => {
      const restaurantRequiresUserCEPStorage = localStorage.getItem(
        '@garcomdigital:restaurantRequiresUserCEP',
      );

      if (restaurantRequiresUserCEPStorage) {
        return restaurantRequiresUserCEPStorage;
      }

      return '';
    },
  );

  const [restaurantAcceptsDelivery, setRestaurantAcceptsDelivery] = useState(
    () => {
      const restaurantAcceptsDeliveryStorage = localStorage.getItem(
        '@garcomdigital:restaurantAcceptsDelivery',
      );

      if (restaurantAcceptsDeliveryStorage) {
        return restaurantAcceptsDeliveryStorage;
      }

      return '';
    },
  );

  //foodpark
  const [hasFoodpark, setHasFoodpark] = useState(() => {
    const hasFoodpark = localStorage.getItem('@garcomdigital:hasFoodpark');

    if (hasFoodpark) {
      return hasFoodpark;
    }

    return '';
  });

  const [foodparkKey, setFoodparkKey] = useState(() => {
    const foodparkKey = localStorage.getItem('@garcomdigital:foodparkKey');

    if (foodparkKey) {
      return foodparkKey;
    }

    return '';
  });

  const [orderSchedulingActive, setOrderSchedulingActive] = useState(() => {
    const orderSchedulingActiveStorage = localStorage.getItem(
      '@garcomdigital:orderSchedulingActive',
    );

    if (orderSchedulingActiveStorage) {
      return orderSchedulingActiveStorage;
    }

    return '';
  });

  const [restaurantDeliveryActive, setRestaurantDeliveryActive] = useState(
    () => {
      const restaurantDeliveryActiveStorage = localStorage.getItem(
        '@garcomdigital:restaurantDeliveryActive',
      );

      if (restaurantDeliveryActiveStorage) {
        return restaurantDeliveryActiveStorage;
      }

      return '';
    },
  );

  const [restaurantWithdrawalActive, setRestaurantWithdrawalActive] = useState(
    () => {
      const restaurantWithdrawalActiveStorage = localStorage.getItem(
        '@garcomdigital:restaurantWithdrawalActive',
      );

      if (restaurantWithdrawalActiveStorage) {
        return restaurantWithdrawalActiveStorage;
      }

      return '';
    },
  );

  const [
    restaurantDeliveryTimeAddress,
    setRestaurantDeliveryTimeAddress,
  ] = useState(() => {
    const restaurantDeliveryTimeAddressStorage = localStorage.getItem(
      '@garcomdigital:restaurantDeliveryTimeAddress',
    );

    if (restaurantDeliveryTimeAddressStorage) {
      return restaurantDeliveryTimeAddressStorage;
    }

    return '';
  });

  const [restaurantDeliveryTime, setRestaurantDeliveryTime] = useState(() => {
    const restaurantDeliveryTimeStorage = localStorage.getItem(
      '@garcomdigital:restaurantDeliveryTime',
    );

    if (restaurantDeliveryTimeStorage) {
      return restaurantDeliveryTimeStorage;
    }

    return '';
  });

  const [restaurantWithdrawalTime, setRestaurantWithdrawalTime] = useState(
    () => {
      const restaurantWithdrawalTimeStorage = localStorage.getItem(
        '@garcomdigital:restaurantWithdrawalTime',
      );

      if (restaurantWithdrawalTimeStorage) {
        return restaurantWithdrawalTimeStorage;
      }

      return '';
    },
  );

  const [restaurantCouponCode, setRestaurantCouponCode] = useState(() => {
    const restaurantCouponCodeStorage = localStorage.getItem(
      '@garcomdigital:restaurantCouponCode',
    );

    if (restaurantCouponCodeStorage) {
      return restaurantCouponCodeStorage;
    }

    return '';
  });

  const [userDeliveryType, setUserDeliveryType] = useState(() => {
    const userDeliveryTypeStorage = localStorage.getItem(
      '@garcomdigital:userDeliveryType',
    );

    if (userDeliveryTypeStorage) {
      return userDeliveryTypeStorage;
    }

    return '';
  });

  const [isScheduling, setIsScheduling] = useState(() => {
    const isSchedulingStorage = localStorage.getItem(
      '@garcomdigital:isScheduling',
    );

    if (isSchedulingStorage) {
      return isSchedulingStorage;
    }

    return false;
  });

  const [scheduledTime, setScheduledTime] = useState(() => {
    const scheduledTimeStorage = localStorage.getItem(
      '@garcomdigital:scheduledTime',
    );

    if (scheduledTimeStorage) {
      return scheduledTimeStorage;
    }

    return '';
  });

  const [userAddressId, setUserAddressId] = useState(() => {
    const userAddressIdStorage = localStorage.getItem(
      '@garcomdigital:userAddressId',
    );

    if (userAddressIdStorage) {
      return userAddressIdStorage;
    }

    return '';
  });

  const [userAddress, setUserAddress] = useState(() => {
    const userAddressStorage = localStorage.getItem(
      '@garcomdigital:userAddress',
    );

    if (userAddressStorage) {
      return JSON.parse(userAddressStorage);
    }

    return '';
  });

  const [restaurantDeliveryTax, setRestaurantDeliveryTax] = useState(() => {
    const restaurantDeliveryTaxStorage = localStorage.getItem(
      '@garcomdigital:restaurantDeliveryTax',
    );

    if (restaurantDeliveryTaxStorage) {
      return restaurantDeliveryTaxStorage;
    }

    return '';
  });

  // const [
  //   restaurantDeliveryMinimumPrice,
  //   setRestaurantDeliveryMinimumPrice,
  // ] = useState(() => {
  //   const restaurantDeliveryMinimumPriceStorage = localStorage.getItem(
  //     '@garcomdigital:restaurantDeliveryMinimumPrice',
  //   );

  //   if (restaurantDeliveryMinimumPriceStorage) {
  //     return restaurantDeliveryMinimumPriceStorage;
  //   }

  //   return '';
  // });

  const [isDeliveryByDistance, setIsDeliveryByDistance] = useState(() => {
    const isDeliveryByDistanceStorage = localStorage.getItem(
      '@garcomdigital:isDeliveryByDistance',
    );

    if (isDeliveryByDistanceStorage) {
      return isDeliveryByDistanceStorage;
    }

    return '';
  });

  const [userChange, setUserChange] = useState(() => {
    const userChangeStorage = localStorage.getItem('@garcomdigital:userChange');

    if (userChangeStorage) {
      return userChangeStorage;
    }

    return '';
  });

  const [restaurantUsername, setRestaurantUsername] = useState(() => {
    const restaurantUsernameStorage = localStorage.getItem(
      '@garcomdigital:restaurantUsername',
    );

    if (restaurantUsernameStorage) {
      return restaurantUsernameStorage;
    }

    return '';
  });

  const [restaurantAddress, setRestaurantAddress] = useState(() => {
    const restaurantAddressStorage = localStorage.getItem(
      '@garcomdigital:restaurantAddress',
    );

    if (restaurantAddressStorage) {
      return JSON.parse(restaurantAddressStorage);
    }

    return '';
  });

  const [modalPix, setModalPix] = useState(() => {
    const modalPixStorage = localStorage.getItem('@garcomdigital:modalPix');

    if (modalPixStorage) {
      return modalPixStorage;
    }

    return true;
  });

  const [cashbackAdvice, setCashbackAdvice] = useState(() => {
    const cashbackAdviceStorage = localStorage.getItem(
      '@garcomdigital:cashbackAdvice',
    );

    if (cashbackAdviceStorage) {
      return cashbackAdviceStorage;
    }

    return true;
  });

  const [hasOrderAdvice, sethasOrderAdvice] = useState(() => {
    const hasOrderAdviceStorage = localStorage.getItem(
      '@garcomdigital:hasOrderAdvice',
    );

    if (hasOrderAdviceStorage) {
      return hasOrderAdviceStorage;
    }

    return true;
  });

  const [restaurantPixelId, setRestaurantPixelId] = useState(() => {
    const restaurantPixelIdStorage = localStorage.getItem(
      '@garcomdigital:restaurantPixelId',
    );

    if (restaurantPixelIdStorage) {
      return restaurantPixelIdStorage;
    }

    return '';
  });

  const [restaurantPixelId2, setRestaurantPixelId2] = useState(() => {
    const restaurantPixelIdStorage2 = localStorage.getItem(
      '@garcomdigital:restaurantPixelId2',
    );

    if (restaurantPixelIdStorage2) {
      return restaurantPixelIdStorage2;
    }

    return '';
  });

  const [restaurantIsActive, setRestaurantIsActive] = useState(() => {
    const restaurantIsActiveStorage = localStorage.getItem(
      '@garcomdigital:restaurantIsActive',
    );

    if (restaurantIsActiveStorage) {
      return restaurantIsActiveStorage;
    }

    return true;
  });

  const [restaurantMetaToken, setRestaurantMetaToken] = useState(() => {
    const restaurantMetaTokenStorage = localStorage.getItem(
      '@garcomdigital:restaurantMetaToken',
    );

    if (restaurantMetaTokenStorage) {
      return restaurantMetaTokenStorage;
    }

    return '';
  });

  const [restaurantMetaToken2, setRestaurantMetaToken2] = useState(() => {
    const restaurantMetaTokenStorage2 = localStorage.getItem(
      '@garcomdigital:restaurantMetaToken2',
    );

    if (restaurantMetaTokenStorage2) {
      return restaurantMetaTokenStorage2;
    }

    return '';
  });

  const [subscriptionIdPixel, setSubscriptionIdPixel] = useState(() => {
    const subscriptionIdPixelStorage = localStorage.getItem(
      '@garcomdigital:subscriptionIdPixel',
    );

    if (subscriptionIdPixelStorage) {
      return subscriptionIdPixelStorage;
    }

    return '';
  });

  const [subscriptionIdPixel2, setSubscriptionIdPixel2] = useState(() => {
    const subscriptionIdPixelStorage2 = localStorage.getItem(
      '@garcomdigital:subscriptionIdPixel2',
    );

    if (subscriptionIdPixelStorage2) {
      return subscriptionIdPixelStorage2;
    }

    return '';
  });

  const [
    restaurantWithdrawallMinimumPrice,
    setRestaurantWithdrawalMinimumPrice,
  ] = useState(() => {
    const restaurantWithdrawallMinimumPriceStorage = localStorage.getItem(
      '@garcomdigital:restaurantWithdrawallMinimumPrice',
    );

    if (restaurantWithdrawallMinimumPriceStorage) {
      return restaurantWithdrawallMinimumPriceStorage;
    }

    return 0;
  });

  const [
    restaurantDeliveryMinimumPrice,
    setRestaurantDeliveryMinimumPrice,
  ] = useState(() => {
    const restaurantDeliveryMinimumPricesStorage = localStorage.getItem(
      '@garcomdigital:restaurantDeliveryMinimumPrice',
    );

    if (restaurantDeliveryMinimumPricesStorage) {
      return restaurantDeliveryMinimumPricesStorage;
    }

    return 0;
  });

  function setMinimumPrices(delivery_minimum_price, withdrawal_minimum_price) {
    setRestaurantDeliveryMinimumPrice(delivery_minimum_price);
    setRestaurantWithdrawalMinimumPrice(withdrawal_minimum_price);
  }

  function getBrowserLocales(options = {}) {
    const defaultOptions = {
      languageCodeOnly: false,
    };
    const opt = {
      ...defaultOptions,
      ...options,
    };
    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;
    if (!browserLocales) {
      return undefined;
    }
    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim();
      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  }

  const formatValue = value => {
    const valueCurr = Intl.NumberFormat(getBrowserLocales, {
      style: 'currency',
      currency: restaurantCurrency || 'BRL',
    }).format(value);

    return valueCurr;
  };

  const [menuTranslationsEnabled, setMenuTranslationsEnabled] = useState(() => {
    const menuTranslationsEnabledStorage = localStorage.getItem(
      '@garcomdigital:menuTranslationsEnabled',
    );

    if (menuTranslationsEnabledStorage) {
      return menuTranslationsEnabledStorage;
    }

    return false;
  });

  const [menuTranslationsLanguage, setMenuTranslationsLanguage] = useState(
    () => {
      const menuTranslationsLanguageStorage = localStorage.getItem(
        '@garcomdigital:menuTranslationsLanguage',
      );

      if (menuTranslationsLanguageStorage) {
        return menuTranslationsLanguageStorage;
      }

      return 'portuguese';
    },
  );

  const [redirectType, setRedirectType] = useState(() => {
    const redirectTypeStorage = localStorage.getItem(
      '@garcomdigital:redirectType',
    );

    if (redirectTypeStorage) {
      return redirectTypeStorage;
    }

    return 'table';
  });

  const [restaurantGreetinMessage, setRestaurantGreetinMessage] = useState(
    () => {
      const restaurantGreetinMessageStorage = localStorage.getItem(
        '@garcomdigital:restaurantGreetinMessage',
      );

      if (restaurantGreetinMessageStorage) {
        return restaurantGreetinMessageStorage;
      }

      return '';
    },
  );

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:redirectType', redirectType);
  }, [redirectType]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:menuTranslationsLanguage',
      menuTranslationsLanguage,
    );
  }, [menuTranslationsLanguage]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:menuTranslationsEnabled',
      menuTranslationsEnabled,
    );
  }, [menuTranslationsEnabled]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userToken', userToken);

    if (userToken && userToken !== 'null') {
      const manager = new WebsocketManager(userToken);

      manager.addMessageCallback(-1, data => {
        console.log('AuthContext: socket new data');
      });

      setWebsocketManager(manager);
    } else if (websocketManager) {
      websocketManager.close();
      setWebsocketManager(null);
    }
  }, [userToken]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantGreetinMessage',
      restaurantGreetinMessage,
    );
  }, [restaurantGreetinMessage]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantDeliveryMinimumPrice',
      restaurantDeliveryMinimumPrice,
    );
  }, [restaurantDeliveryMinimumPrice]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantWithdrawallMinimumPrice',
      restaurantWithdrawallMinimumPrice,
    );
  }, [restaurantWithdrawallMinimumPrice]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:hasOrderAdvice', hasOrderAdvice);
  }, [hasOrderAdvice]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:modalPix', modalPix);
  }, [modalPix]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:cashbackAdvice', cashbackAdvice);
  }, [cashbackAdvice]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userChange', userChange);
  }, [userChange]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:isDeliveryByDistance',
      isDeliveryByDistance,
    );
  }, [isDeliveryByDistance]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:pixCodePayment', pixCodePayment);
  }, [pixCodePayment]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:zoopPixCodePayment',
      zoopPixCodePayment,
    );
  }, [zoopPixCodePayment]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantDeliveryPayments',
      JSON.stringify(restaurantDeliveryPayments),
    );
  }, [restaurantDeliveryPayments]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantDeliveryTax',
      restaurantDeliveryTax,
    );
  }, [restaurantDeliveryTax]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:userAddress',
      JSON.stringify(userAddress),
    );
  }, [userAddress]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userAddressId', userAddressId);
  }, [userAddressId]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userDeliveryType', userDeliveryType);
  }, [userDeliveryType]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:isScheduling', isScheduling);
  }, [isScheduling]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:scheduledTime', scheduledTime);
  }, [scheduledTime]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantCouponCode',
      restaurantCouponCode,
    );
  }, [restaurantCouponCode]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:restaurantPhone', restaurantPhone);
  }, [restaurantPhone]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:restaurantIsOpen', restaurantIsOpen);
  }, [restaurantIsOpen]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantUsername',
      restaurantUsername,
    );
  }, [restaurantUsername]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantDeliveryTime',
      restaurantDeliveryTime,
    );
  }, [restaurantDeliveryTime]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantDeliveryTimeAddress',
      restaurantDeliveryTimeAddress,
    );
  }, [restaurantDeliveryTimeAddress]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantWithdrawalTime',
      restaurantWithdrawalTime,
    );
  }, [restaurantWithdrawalTime]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantAcceptsPicpay',
      restaurantAcceptsPicpay,
    );
  }, [restaurantAcceptsPicpay]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantAcceptsPaytime',
      restaurantAcceptsPaytime,
    );
  }, [restaurantAcceptsPaytime]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantAcceptsWithdrawal',
      restaurantAcceptsWithdrawal,
    );
  }, [restaurantAcceptsWithdrawal]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:orderSchedulingActive',
      orderSchedulingActive,
    );
  }, [orderSchedulingActive]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantAcceptsDelivery',
      restaurantAcceptsDelivery,
    );
  }, [restaurantAcceptsDelivery]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:hasFoodpark', hasFoodpark);
  }, [hasFoodpark]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:foodparkKey', foodparkKey);
  }, [foodparkKey]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantAcceptsPaymentAtWithdrawal',
      restaurantAcceptsPaymentAtWithdrawal,
    );
  }, [restaurantAcceptsPaymentAtWithdrawal]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantRequiresUserCEP',
      restaurantRequiresUserCEP,
    );
  }, [restaurantRequiresUserCEP]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantDeliveryActive',
      restaurantDeliveryActive,
    );
  }, [restaurantDeliveryActive]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantOnlyQrcode',
      restaurantOnlyQrcode,
    );
  }, [restaurantOnlyQrcode]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantOnlyDelivery',
      restaurantOnlyDelivery,
    );
  }, [restaurantOnlyDelivery]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantWithdrawalActive',
      restaurantWithdrawalActive,
    );
  }, [restaurantWithdrawalActive]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:userRecentlyProduct',
      JSON.stringify(userRecentlyProduct),
    );
  }, [userRecentlyProduct]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantLocationLimited',
      restaurantLocationLimited,
    );
  }, [restaurantLocationLimited]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:instagram', instagram);
  }, [instagram]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantAcceptsPix',
      restaurantAcceptsPix,
    );
  }, [restaurantAcceptsPix]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantUseCommand',
      restaurantUseCommand,
    );
  }, [restaurantUseCommand]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:userLocation',
      JSON.stringify(userLocation),
    );
  }, [userLocation]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantTaxService',
      restaurantTaxService,
    );
  }, [restaurantTaxService]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantTaxServiceValue',
      restaurantTaxServiceValue,
    );
  }, [restaurantTaxServiceValue]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantFantasyName',
      restaurantFantasyName,
    );
  }, [restaurantFantasyName]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:restaurantIsOpen', restaurantIsOpen);
  }, [restaurantIsOpen]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:basketId', basketId);
  }, [basketId]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:billId', billId);
  }, [billId]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userPhone', userPhone);
  }, [userPhone]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userName', userName);
  }, [userName]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userToken', userToken);
  }, [userToken]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:sessionKey', sessionKey);
  }, [sessionKey]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:tableNumber', tableNumber);
  }, [tableNumber]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:tableKey', tableKey);
  }, [tableKey]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:tableType', tableType);
  }, [tableType]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:tableId', tableId);
  }, [tableId]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:restaurantId', restaurantId);
  }, [restaurantId]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:restaurantName', restaurantName);
  }, [restaurantName]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:restaurantAvatar', restaurantAvatar);
  }, [restaurantAvatar]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantAddress',
      JSON.stringify(restaurantAddress),
    );
  }, [restaurantAddress]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:orderPicPayId', orderPicPayId);
  }, [orderPicPayId]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userFirstName', userFirstName);
  }, [userFirstName]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userLastName', userLastName);
  }, [userLastName]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userEmail', userEmail);
  }, [userEmail]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:userChosePaySms', userChosePaySms);
  }, [userChosePaySms]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:clientPaySms', clientPaySms);
  }, [clientPaySms]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantHasSmsService',
      restaurantHasSmsService,
    );
  }, [restaurantHasSmsService]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:isSmsServiceOptional',
      isSmsServiceOptional,
    );
  }, [isSmsServiceOptional]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:userPaymentMethod',
      JSON.stringify(userPaymentMethod),
    );
  }, [userPaymentMethod]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:picpayUrlPayment', picpayUrlPayment);
  }, [picpayUrlPayment]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:picpayReferenceId', picpayReferenceId);
  }, [picpayReferenceId]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantCurrency',
      restaurantCurrency,
    );
  }, [restaurantCurrency]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:lastPixCode', lastPixCode);
  }, [lastPixCode]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:tokenClube', tokenClube);
  }, [tokenClube]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:keyClube', keyClube);
  }, [keyClube]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:hasClube', hasClube);
  }, [hasClube]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:clube', JSON.stringify(clube));
  }, [clube]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:restaurantPixelId', restaurantPixelId);
  }, [restaurantPixelId]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantPixelId2',
      restaurantPixelId2,
    );
  }, [restaurantPixelId2]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantIsActive',
      restaurantIsActive,
    );
  }, [restaurantIsActive]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantMetaToken',
      restaurantMetaToken,
    );
  }, [restaurantMetaToken]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:restaurantMetaToken2',
      restaurantMetaToken2,
    );
  }, [restaurantMetaToken2]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:subscriptionIdPixel',
      subscriptionIdPixel,
    );
  }, [subscriptionIdPixel]);

  useEffect(() => {
    localStorage.setItem(
      '@garcomdigital:subscriptionIdPixel2',
      subscriptionIdPixel2,
    );
  }, [subscriptionIdPixel2]);

  const location = useLocation();

  const aleatoryId = uuid();
  const currentUrl = window.location.href;
  useEffect(() => {
    if (restaurantPixelId && tableType !== 'delivery') {
      ReactPixel.fbq('track', 'PageView', {}, { eventID: aleatoryId });
    }

    if (restaurantPixelId2 && tableType === 'delivery') {
      ReactPixel.fbq('track', 'PageView', {}, { eventID: aleatoryId });
    }

    if (restaurantPixelId && restaurantMetaToken && tableType !== 'delivery') {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId}/events?=${restaurantMetaToken}`,
        {
          pixel_id: restaurantPixelId,
          access_token: restaurantMetaToken,
          data: [
            {
              event_name: 'PageView',
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              event_id: aleatoryId,
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel,
              },
              custom_data: {
                url: currentUrl,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    }

    if (
      restaurantPixelId2 &&
      restaurantMetaToken2 &&
      tableType === 'delivery'
    ) {
      console.log('delivery aqui');
      console.log(restaurantPixelId2, restaurantMetaToken2, tableType);
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId2}/events?=${restaurantMetaToken2}`,
        {
          pixel_id: restaurantPixelId2,
          access_token: restaurantMetaToken2,
          data: [
            {
              event_name: 'PageView',
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              event_id: aleatoryId,
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel2,
              },
              custom_data: {
                url: currentUrl,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    }
  }, [location.pathname]);

  const setUserPhone = useCallback(number => {
    setUserphone(number);
  }, []);

  const setRestaurant = useCallback(
    (
      id,
      name,
      fantasyName,
      serviceTax,
      serviceTaxValue,
      instagram,
      currency,
      phone,
      token_clube,
      has_clube,
      pixel_id,
      pixel_id2,
      is_active,
      meta_access_token,
      meta_access_token2,
      subscriptionIdFb,
      subscriptionIdFb2,
    ) => {
      setRestaurantId(id);
      setRestaurantName(name);
      setInstagram(instagram);
      setRestaurantFantasyName(fantasyName);
      setRestaurantTaxService(serviceTax);
      setRestaurantTaxServiceValue(serviceTaxValue);
      setRestaurantCurrency(currency);
      setRestaurantPhone(phone);
      setTokenClube(token_clube);
      setHasClube(has_clube);
      setRestaurantPixelId(pixel_id);
      setRestaurantPixelId2(pixel_id2);
      setRestaurantIsActive(is_active);
      setRestaurantMetaToken(meta_access_token);
      setRestaurantMetaToken2(meta_access_token2);
      setSubscriptionIdPixel(subscriptionIdFb);
      setSubscriptionIdPixel2(subscriptionIdFb2);
    },
    [],
  );

  const setTable = useCallback((number, key, type, id) => {
    setTableNumber(number);
    setTableKey(key);
    setTableType(type);
    setTableId(id);
  }, []);

  const setUser = useCallback(
    (phone, name, email) => {
      setUserPhone(phone);
      setUserName(name);
      setUserEmail(email);
    },
    [setUserPhone, setUserName, setUserEmail],
  );

  //pixel_fb
  function handleSendDataToPixel() {
    const aleatorID = uuid();
    if (restaurantPixelId && tableType !== 'delivery') {
      ReactPixel.fbq(
        'track',
        'CompleteRegistration',
        {
          status: true,
        },
        {
          eventID: aleatorID,
        },
      );
    }

    if (restaurantPixelId2 && tableType === 'delivery') {
      ReactPixel.fbq(
        'track',
        'CompleteRegistration',
        {
          status: true,
        },
        {
          eventID: aleatorID,
        },
      );
    }

    if (restaurantPixelId && restaurantMetaToken && tableType !== 'delivery') {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId}/events?=${restaurantMetaToken}`,
        {
          pixel_id: restaurantPixelId,
          access_token: restaurantMetaToken,
          data: [
            {
              event_name: 'CompleteRegistration',
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              event_id: aleatorID,
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel,
              },
              custom_data: {
                status: true,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    }

    if (
      restaurantPixelId2 &&
      restaurantMetaToken2 &&
      tableType === 'delivery'
    ) {
      console.log('delivery aqui');
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId2}/events?=${restaurantMetaToken2}`,
        {
          pixel_id: restaurantPixelId2,
          access_token: restaurantMetaToken2,
          data: [
            {
              event_name: 'CompleteRegistration',
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              event_id: aleatorID,
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel2,
              },
              custom_data: {
                status: true,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    }
  }

  const userLogin = useCallback(
    async (phone, name) => {
      try {
        if (tableType === 'command') {
          await api.post(`public/buyers`, { phone, name, table_id: tableId });
        } else {
          await api.post(`public/buyers`, { phone, name });
        }

        const response = await api.post('public/sessions', { phone });

        const { token, user } = response.data;

        if (!token) {
          console.log('token does not exist');
        }

        api.defaults.headers.Authorization = `Bearer ${token}`;

        setUserToken(token);
        setUserName(user.name);
        if (restaurantPixelId && tableType !== 'delivery') {
          handleSendDataToPixel();
        }

        if (restaurantPixelId2 && tableType === 'delivery') {
          handleSendDataToPixel();
        }
      } catch (error) {
        if (!error.response?.ok && error.response) {
          switch (error.response.data.errorType) {
            case 'service_unavailable':
              history.push(`/app/systemError`);
              break;
            case 'buyer_not_found_in_command':
              return false;
              break;
            default:
              console.log(error);
              break;
          }
        } else {
          console.log(error);
        }
      }
    },
    [tableId],
  );

  const logOut = useCallback(() => {
    // localStorage.removeItem('@garcomdigital:userPhone');
    // localStorage.removeItem('@garcomdigital:userToken');
    // localStorage.removeItem('@garcomdigital:sessionKey');
    localStorage.removeItem('@garcomdigital:restaurantName');
    localStorage.removeItem('@garcomdigital:tableKey');
    localStorage.removeItem('@garcomdigital:tableNumber');
    localStorage.removeItem('@garcomdigital:restaurantId');
    localStorage.removeItem('@garcomdigital:restaurantAvatar');

    localStorage.removeItem('@garcomdigital:userPhone');
    localStorage.removeItem('@garcomdigital:userFirstName');
    localStorage.removeItem('@garcomdigital:userLastName');
    localStorage.removeItem('@garcomdigital:userEmail');
    localStorage.removeItem('@garcomdigital:lastPixCode');

    // setUserphone('');
    // setUserToken('');
    // setSessionKey('');
    setRestaurantId('');
    setRestaurantName('');
    setTableKey('');
    setTableNumber('');
    setTableType('');
    setLastPixCode('');
  }, []);

  const changeUserInfo = useCallback(() => {
    // localStorage.removeItem('@garcomdigital:userPhone');
    // localStorage.removeItem('@garcomdigital:userToken');
    // localStorage.removeItem('@garcomdigital:sessionKey');

    setUserphone('');

    localStorage.removeItem('@garcomdigital:userPhone');
    localStorage.removeItem('@garcomdigital:userName');

    localStorage.removeItem('@garcomdigital:userEmail');

    // setUserphone('');
    // setUserToken('');
    // setSessionKey('');
  }, []);

  async function SendErrorToServer(data) {
    await api.post('/public/client/log-error', {
      error: data,
    });
  }

  const tableLogin = useCallback(
    async (
      lastOrder,
      command_table_number,
      closeCart,
      payment_method = null,
      payment_token = null,
    ) => {
      if (restaurantLocationLimited.toString() === 'true') {
        try {
          if (payment_method === 'pix') {
            setLoadingPix(true);
            setPhraseToPix(true);
            setUserPaymentMethod({ name: 'pix' });
          }
          const res = await api.post('client/orders', {
            restaurant_name: restaurantName,
            table_key: tableKey,
            order: lastOrder,
            client_position: {
              latitude: userLocation.latitude,
              longitude: userLocation.longitude,
            },
            command_table_number,
            payment_method: payment_method || null,
            payment_token: payment_method === 'credito' ? payment_token : null,
          });

          const { updatedBasket, tableSession, updatedBill } = res.data;

          if (payment_method === 'pix') {
            const { pix_info } = res.data;

            setPixCodePayment(pix_info.qrcode);
            setZoopPixCodePayment(pix_info.zoop_id);

            history.push('/app/prepaid/almost-there');

            setLoadingPix(false);
            setPhraseToPix(false);
          }

          if (payment_method === 'credito') {
            setUserPaymentMethod({ name: 'credito' });

            history.push('/app/prepaid/almost-there');
          }

          setBasketId(updatedBasket.basket_id);
          setBillId(updatedBill.id);
          closeCart();

          if (!payment_method || payment_method === 'courtesy') {
            setSessionKey(tableSession.key);
            history.push('/app/confirmorder');
          }
        } catch (err) {
          if (!err.response?.ok && err.response) {
            switch (err.response.data.errorType) {
              case 'restaurant_closed':
                setError(true);
                setErrorMessage(
                  'Restaurante fechado, tente novamente mais tarde, por favor!',
                );
                setErrorAction([
                  () => {
                    history.push(`/menu/${restaurantId}`);
                  },
                ]);
                break;

              case 'service_unavailable':
                history.push(`/app/systemError`);

                break;

              case 'paytime_api_request_failed':
                setIsModalErrorOpen(true);
                setModalErrorPhrase(
                  'Erro ao se comunicar com a operadora do cartão. Reveja os dados digitados ou entre em contato com o seu banco.',
                );
                break;

              case 'table_session_closing':
                setError(true);
                setErrorMessage(
                  'Esta mesa está em fechamento, favor solicitar a liberação ao garçom.',
                );
                setErrorAction([
                  () => {
                    history.push(`/menu/${restaurantId}`);
                  },
                ]);
                break;

              case 'wrong_restaurant_id':
                logOut();
                setError(true);
                setErrorMessage(
                  'Houve um erro inesperado no celular, mas não se preocupe, basta dar ok, e refazer o pedido.',
                );
                setErrorAction([
                  () => {
                    history.push(`/app/auto`);
                  },
                ]);
                break;

              case 'user_with_open_session':
                const { redirect_url } = err.response.data.payload;

                setError(true);
                setErrorMessage(
                  'Usuário já logado em outra mesa, estamos te redirecionando para sua mesa anterior',
                );

                setErrorAction([
                  () => {
                    window.location.href = redirect_url;
                  },
                ]);
                break;

              case 'products_not_available':
                openModalProductError(err.response.data.payload);

                // setError(true);
                // setErrorMessage(
                //   `Olá, não poderemos concluir o seu pedido pois o item a seguir acabou de esgotar ou não está mais disponível:  ${products.map(
                //     item => ` ${item} `,
                //   )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                //       `,
                // );

                setLoadingPix(false);

                setErrorAction([
                  () => {
                    window.location.reload();
                  },
                ]);
                break;

              case 'far_from_restaurant':
                history.push(`/app/farlocationerror`);
                break;

              case 'no_geolocation_sent':
                history.push(`/app/locationError`);
                break;

              case 'wrong_product':
                setError(true);
                setErrorMessage(
                  'Esse produto não existe neste restaurante, favor verificar no cardápio.',
                );
                setErrorAction([
                  () => {
                    history.push(`/menu/${restaurantId}`);
                  },
                ]);
                break;

              case 'table_session_not_found':
                localStorage.removeItem('@garcomdigital:sessionKey');
                setSessionKey('aaa');
                setError(true);
                setErrorMessage(
                  'Ocorreu um erro ao efetuar o pedido. Favor tentar novamente.',
                );
                setErrorAction([
                  () => {
                    history.push(`/app/cart`);
                  },
                ]);

                break;

              default:
                setError(true);
                setErrorMessage(
                  `Ocorreu um erro ao efetuar o pedido. Favor tentar novamente, ou mostar o erro ao gerente. : ${err.message}`,
                );
                setErrorAction([
                  () => {
                    history.push(`/menu/${restaurantId}`);
                  },
                ]);
                closeCart();
                break;
            }
          } else {
            await api.post('/public/client/log-error', {
              error: err.message,
            });
          }
        }
      }

      if (restaurantLocationLimited.toString() === 'false') {
        try {
          if (payment_method === 'pix') {
            setLoadingPix(true);
            setPhraseToPix(true);
          }
          const res = await api.post('client/orders', {
            restaurant_name: restaurantName,
            table_key: tableKey,
            order: lastOrder,
            client_position: {
              latitude: 0,
              longitude: 0,
            },
            command_table_number,
            payment_method: payment_method || null,
            payment_token: payment_method === 'credito' ? payment_token : null,
          });

          const { updatedBasket, tableSession, updatedBill } = res.data;

          if (payment_method === 'pix') {
            const { pix_info } = res.data;

            setPixCodePayment(pix_info.qrcode);
            setZoopPixCodePayment(pix_info.zoop_id);

            history.push('/app/prepaid/almost-there');
            setUserPaymentMethod({ name: 'pix' });
            setLoadingPix(false);
            setPhraseToPix(false);
          }

          if (payment_method === 'credito') {
            setUserPaymentMethod({ name: 'credito' });

            history.push('/app/prepaid/almost-there');
          }

          setBasketId(updatedBasket.basket_id);
          setBillId(updatedBill.id);
          closeCart();

          if (!payment_method || payment_method === 'courtesy') {
            setSessionKey(tableSession.key);
            history.push('/app/confirmorder');
          }
        } catch (err) {
          if (!err.response?.ok && err.response) {
            switch (err.response.data.errorType) {
              case 'restaurant_closed':
                setError(true);
                setErrorMessage(
                  'Restaurante fechado, tente novamente mais tarde, por favor!',
                );
                setErrorAction([
                  () => {
                    history.push(`/menu/${restaurantId}`);
                  },
                ]);
                break;

              case 'lounge_price_limit':
                setError(true);
                setErrorMessage(err.response.data.message);
                setErrorAction([
                  () => {
                    history.push(`/menu/${restaurantId}`);
                  },
                ]);
                break;

              case 'service_unavailable':
                history.push(`/app/systemError`);

                break;

              case 'table_session_closing':
                setError(true);
                setErrorMessage(
                  'Esta mesa está em fechamento, favor solicitar a liberação ao garçom.',
                );
                setErrorAction([
                  () => {
                    history.push(`/menu/${restaurantId}`);
                  },
                ]);
                break;

              case 'wrong_restaurant_id':
                logOut();
                setError(true);
                setErrorMessage(
                  'Houve um erro inesperado no celular, mas não se preocupe, basta dar ok, e refazer o pedido.',
                );
                setErrorAction([
                  () => {
                    history.push(`/app/auto`);
                  },
                ]);
                break;

              case 'user_with_open_session':
                const { redirect_url } = err.response.data.payload;

                setError(true);
                setErrorMessage(
                  'Usuário já logado em outra mesa, estamos te redirecionando para sua mesa anterior',
                );

                tableType === 'prepaid' && setLoadingPix(false);

                setErrorAction([
                  () => {
                    window.location.href = redirect_url;
                  },
                ]);
                break;

              case 'paytime_api_request_failed':
                setIsModalErrorOpen(true);
                setModalErrorPhrase(
                  'Erro ao se comunicar com a operadora do cartão. Reveja os dados digitados ou entre em contato com o seu banco.',
                );
                break;

              case 'products_not_available':
                openModalProductError(err.response.data.payload);

                // setError(true);
                // setErrorMessage(
                //   `Olá, não poderemos concluir o seu pedido pois o item a seguir acabou de esgotar ou não está mais disponível: ${products.map(
                //     item => ` ${item} `,
                //   )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                //       `,
                // );

                setLoadingPix(false);

                setErrorAction([
                  () => {
                    window.location.reload();
                  },
                ]);
                break;

              case 'far_from_restaurant':
                history.push(`/app/farlocationerror`);
                break;

              case 'no_geolocation_sent':
                history.push(`/app/locationError`);
                break;

              case 'wrong_product':
                setError(true);
                setErrorMessage(
                  'Esse produto não existe neste restaurante, favor verificar no cardápio.',
                );
                setErrorAction([
                  () => {
                    history.push(`/menu/${restaurantId}`);
                  },
                ]);
                break;

              case 'table_session_not_found':
                localStorage.removeItem('@garcomdigital:sessionKey');
                setSessionKey('aaa');

                setError(true);
                setErrorMessage(
                  'Ocorreu um erro ao efetuar o pedido. Favor tentar novamente.',
                );
                setErrorAction([
                  () => {
                    history.push(`/app/cart`);
                  },
                ]);

                break;

              default:
                setError(true);
                setErrorMessage(
                  `Ocorreu um erro ao efetuar o pedido. Favor tentar novamente, ou mostar o erro ao gerente. : ${err.message}`,
                );
                setErrorAction([
                  () => {
                    history.push(`/menu/${restaurantId}`);
                  },
                ]);
                closeCart();
                break;
            }
          } else {
            await api.post('/public/client/log-error', {
              error: err.message,
            });
          }
        }
      }
    },
    [
      restaurantName,
      tableKey,
      history,
      restaurantId,
      userLocation,
      restaurantLocationLimited,
      logOut,
    ],
  );

  const userLogout = useCallback(() => {
    // localStorage.removeItem('@garcomdigital:userPhone');
    localStorage.removeItem('@garcomdigital:userToken');
    localStorage.removeItem('@garcomdigital:sessionKey');

    // setUserphone('');
    setUserToken('');
    setSessionKey('');
  }, []);

  // useEffect(()=>{
  //  const interval = setInterval(()=> { setModalPix(true); console.log('laterei') }, 3600000)

  //  return () => clearInterval(interval)
  // }, [])

  const [productsNotAvailable, setProductsNotAvailable] = useState([]);
  const [isProductErrorModalOpened, setIsProductErrorModalOpened] = useState(
    false,
  );

  function openModalProductError(data) {
    setProductsNotAvailable(data);
    setIsProductErrorModalOpened(true);
  }

  function toggleModalProductError() {
    setIsProductErrorModalOpened(!isProductErrorModalOpened);
  }

  //pixel-init
  useEffect(() => {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
      samesite: 'none', // Define SameSite como None
      secure: true, // Define o cookie como seguro (Secure)
      first_party_cookies: ['_fbp'],
    };

    if (restaurantPixelId && tableType !== 'delivery') {
      ReactPixel.init(restaurantPixelId, advancedMatching, options);

      console.log('Iniciando pixel presencial');
    }

    if (restaurantPixelId2 && tableType === 'delivery') {
      ReactPixel.init(restaurantPixelId2, advancedMatching, options);

      console.log('Iniciando pixel delivery');
    }
  }, [restaurantPixelId, restaurantPixelId2]);

  //ultima localização
  const [lastLocation, setLastLocation] = useState(null);
  const [previousLocation, setPreviousLocation] = useState(null);

  useEffect(() => {
    const handleLocationChange = (location, action) => {
      if (action === 'PUSH' || action === 'POP') {
        setPreviousLocation(lastLocation); // Armazena a última localização como a localização anterior
        setLastLocation(location.pathname); // Define a nova última localização
      }
    };

    const unlisten = history.listen(handleLocationChange);

    return () => {
      unlisten();
    };
  }, [history, lastLocation]);

  // State do modal de Call to Action do cadastro do clube

  const [modalCTA, setModalCTA] = useState(true);

  return loadingPix ? (
    <LoadingPage
      duration={3}
      text={phraseToPix ? 'Gerando código PIX' : 'Fazendo o pedido ...'}
    />
  ) : (
    <AuthContext.Provider
      value={{
        setUserPhone,
        userPhone,
        userName,
        setUserName,
        logOut,
        userLogin,
        tableLogin,
        setRestaurant,
        setTable,
        tableNumber,
        tableId,
        setTableId,
        sessionKey,
        setSessionKey,
        restaurantId,
        restaurantAvatar,
        setRestaurantAvatar,
        restaurantName,
        restaurantIsOpen,
        setRestaurantIsOpen,
        userLogout,
        setBillId,
        billId,
        setBasketId,
        basketId,
        restaurantFantasyName,
        restaurantTaxService,
        restaurantTaxServiceValue,
        restaurantAcceptsPix,
        setRestaurantAcceptsPix,
        userLocation,
        setUserLocation,
        restaurantLocationLimited,
        setRestaurantLocationLimited,
        setUserChosePaySms,
        userChosePaySms,
        setError,
        setUserRecentlyProduct,
        userRecentlyProduct,
        setTableNumber,
        setRestaurantUseCommand,
        restaurantUseCommand,
        tableType,
        instagram,
        setUser,
        changeUserInfo,
        setRestaurantAcceptsPaytime,
        setRestaurantAcceptsPicpay,
        restaurantAcceptsPaytime,
        picpayReferenceId,
        setPicpayReferenceId,
        setRestaurantCouponCode,
        restaurantCouponCode,
        restaurantAcceptsWithdrawal,
        setRestaurantAcceptsWithdrawal,
        restaurantAcceptsPaymentAtWithdrawal,
        restaurantAcceptsDelivery,
        restaurantRequiresUserCEP,
        setRestaurantRequiresUserCEP,
        setRestaurantAcceptsPaymentAtWithdrawal,
        setRestaurantAcceptsDelivery,
        restaurantDeliveryActive,
        setRestaurantDeliveryActive,
        restaurantWithdrawalActive,
        setRestaurantWithdrawalActive,
        userDeliveryType,
        setUserDeliveryType,
        userAddressId,
        setUserAddressId,
        userAddress,
        setUserAddress,
        restaurantDeliveryPayments,
        setRestaurantDeliveryPayments,
        restaurantDeliveryTax,
        setRestaurantDeliveryTax,
        restaurantDeliveryTime,
        setRestaurantDeliveryTime,
        restaurantDeliveryTimeAddress,
        setRestaurantDeliveryTimeAddress,
        restaurantWithdrawalTime,
        setRestaurantWithdrawalTime,
        userChange,
        setUserChange,
        isDeliveryByDistance,
        setIsDeliveryByDistance,
        restaurantAddress,
        setRestaurantAddress,
        setUserPaymentMethod,
        userPaymentMethod,
        formatValue,
        setIsSmsServiceOptional,
        isSmsServiceOptional,
        setClientPaySms,
        clientPaySms,
        restaurantHasSmsService,
        setRestaurantHasSmsService,
        pixCodePayment,
        setPixCodePayment,
        zoopPixCodePayment,
        setZoopPixCodePayment,
        tableKey,
        modalPix,
        setModalPix,
        restaurantPhone,
        cashbackAdvice,
        setCashbackAdvice,
        websocketManager,
        hasOrderAdvice,
        sethasOrderAdvice,
        orderSchedulingActive,
        setOrderSchedulingActive,
        isScheduling,
        setIsScheduling,
        scheduledTime,
        setScheduledTime,
        lastPixCode,
        setLastPixCode,
        restaurantOnlyQrcode,
        setRestaurantOnlyQrcode,
        tokenClube,
        hasClube,
        keyClube,
        setKeyClube,
        restaurantPixelId,
        restaurantPixelId2,
        restaurantIsActive,
        clube,
        setClube,
        setFoodparkKey,
        foodparkKey,
        setHasFoodpark,
        hasFoodpark,
        restaurantOnlyDelivery,
        setRestaurantOnlyDelivery,
        setMinimumPrices,
        restaurantWithdrawallMinimumPrice,
        restaurantDeliveryMinimumPrice,
        menuTranslationsEnabled,
        setMenuTranslationsEnabled,
        setMenuTranslationsLanguage,
        menuTranslationsLanguage,
        restaurantMetaToken,
        restaurantMetaToken2,
        subscriptionIdPixel,
        subscriptionIdPixel2,
        userToken,
        requireTableNumber,
        setRequireTableNumber,
        setRedirectType,
        redirectType,
        setRestaurantGreetinMessage,
        restaurantGreetinMessage,
        isMenuVisible,
        setIsMenuVisible,
        previousLocation,
        modalCTA,
        setModalCTA,
      }}
    >
      {children}
      <AuthError
        error={error}
        errorMessage={errorMessage}
        errorAction={errorAction}
      />

      <Modal
        isOpen={isModalErrorOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>{modalErrorPhrase}</p>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton onClick={toggleModal}>Ok, entendi</ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isProductErrorModalOpened}
        onRequestClose={toggleModalProductError}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <div style={{ background: '#fff' }}>
            <p>
              Olá, não poderemos concluir o seu pedido, pois o item a seguir não
              tem a quantidade suficiente disponível:
            </p>
            {productsNotAvailable &&
              productsNotAvailable.map(item => (
                <p>
                  Item: {item.product} - Disponível: {parseInt(item.available)}
                </p>
              ))}
          </div>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton
            onClick={() => {
              errorAction[0]();
              toggleModalProductError();
            }}
          >
            Ok, entendi
          </ConfirmButton>
        </ModalFooter>
      </Modal>
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    console.log('useAuth must be within a provider');
  }

  return context;
}

export { AuthProvider, useAuth };
